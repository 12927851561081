import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedUser } from '../middleware/userauth';
import UserMenu from '../components/UserMenu';
import VotersTable from './VotersTable';
import apiUrl from '../config'; 

const VotersPage = () => {
  const navigate = useNavigate();
  const [voters, setVoters] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const collectorId = localStorage.getItem('collector_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      
      const response = await axios.get(`${apiUrl}/api/voters/collector/${collectorId}`, { headers });

      
      setVoters(response.data);
      console.log(response.data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  if (!isAuthenticatedUser()) {
    // User is not authenticated, redirect to login
    navigate('/');
    return null;
  }

  return (
    <div>
      {/* Include the UserMenu component here */}
      <UserMenu />
      <h2>Your Voters</h2>
      <VotersTable voters={voters} fetchData={fetchData} />
    </div>
  );
};

export default VotersPage;
