import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedAdmin} from '../middleware/adminauth';
import AdminMenu from '../components/AdminMenu'; // Import the AdminMenu component
import apiUrl from '../config'; 

const { Meta } = Card;

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [totalVoters, setTotalVoters] = useState(0);
  const [totalClicked, setTotalClicked] = useState(0);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      console.log(`${apiUrl}/api/voters/total-registered`);
      console.log(`${apiUrl}api/voters/total-clicked`);
      const responseTotalRegistered = await axios.get(`${apiUrl}/api/voters/total-registered`, { headers });
      const responseTotalClicked = await axios.get(`${apiUrl}/api/voters/total-clicked`, { headers });
    
      setDate(new Date().toLocaleDateString());
      setTotalVoters(responseTotalRegistered.data.totalRegistered);
      setTotalClicked(responseTotalClicked.data.totalClicked);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!isAuthenticatedAdmin()) {
    // User is not authenticated as an admin, redirect to login
     // Clear the token from localStorage
    navigate('/'); // Redirect to the login page
    return;
  }



  return (
    <div>
         <AdminMenu />
      <div style={{ padding: '16px' }}>
        <Row gutter={[16, 16]}>
          <Col span={8} offset={8}>
            <Card style={{ width: '100%' }}>
              <Meta title="Date" description={date} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8} offset={8}>
            <Card style={{ width: '100%' }}>
              <Meta title="Total Voters" description={totalVoters} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8} offset={8}>
            <Card style={{ width: '100%' }}>
              <Meta title="Total Voters Clicked on Link" description={totalClicked} />
            </Card>
          </Col>
        </Row>
      </div>
      {/* Add admin dashboard content here */}
    </div>
  );
};

export default AdminDashboard;
