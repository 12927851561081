import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedUser } from '../middleware/userauth';
import UserMenu from '../components/UserMenu';
import { Modal, Button, Form, Input } from 'antd';
import apiUrl from '../config'; 

const Me = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [editing, setEditing] = useState(true); // Set this to true to automatically open the modal
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const collectorId = localStorage.getItem('collector_id');
      const response = await axios.get(`${apiUrl}/api/collectors/me/${collectorId}`, { headers });
      setUser(response.data);
      setName(response.data.name);
      setEmail(response.data.email);
      setPassword(response.data.password);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const handleSave = async () => {
    try {

        if (password.trim() === '') {
            alert('Password cannot be empty');
            return;
          }  
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const updatedUser = { ...user, name, email, password };
      const collectorId = localStorage.getItem('collector_id');
      await axios.put(`${apiUrl}/api/collectors/me/${collectorId}`, updatedUser, { headers });
      setUser(updatedUser);
      setEditing(false);
    } catch (error) {
      console.log('Error updating data:', error);
    }
    const role = localStorage.getItem('role');
    navigate(`/${role.toLowerCase()}dashboard`); // Navigate to respective dashboard
  };

  const handleCancel = () => {
    // Reset the form fields to their original values
    setName(user.name);
    setEmail(user.email);
    setPassword(''); // You may want to handle password differently (e.g., setting it to null or not showing it at all)
    setEditing(false);
    
    const role = localStorage.getItem('role');
    navigate(`/${role.toLowerCase()}dashboard`); // Navigate to respective dashboard
  };

  if (!isAuthenticatedUser()) {
    // User is not authenticated, redirect to login
    navigate('/');
    return null;
  }

  return (
    <div>
      <UserMenu />
      
      <div>
               <Modal open={editing} onCancel={handleCancel} footer={null} title="My Profile">
          <Form>
            <Form.Item label="Navn">
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item label="Email">
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Form.Item label="Password">
              <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <Button onClick={handleSave}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Me;
