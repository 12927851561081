
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import AdminMenu from '../components/AdminMenu'; // Import the AdminMenu component
import CollectorsTable from './CollectorsTable';
import apiUrl from '../config'; 

const CollectorsPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const [collectors, setCollectors] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${apiUrl}/api/collectors`, { headers });
      setCollectors(response.data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  
  if (!isAuthenticatedAdmin()) {
    // User is not authenticated as an admin, redirect to login
     // Clear the token from localStorage
    navigate('/'); // Redirect to the login page
    return;
  }

  return (
    <div>
      {/* Include the AdminMenu component here */}
      <AdminMenu />
      <h2>Collectors</h2>
      <CollectorsTable collectors={collectors} fetchData={fetchData} />
    </div>
  );
};

export default CollectorsPage;
