
import React, { useState, useEffect, useRef } from 'react';  
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import apiUrl from '../config';

const EmailTemplateEditorPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState('');
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${apiUrl}/api/email-templates/${id}`, {
          headers,
        });

        setEditorState(response.data.body);
        form.setFieldsValue({
          name: response.data.name,
          subject: response.data.subject,
          body: response.data.body,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (id && isMounted.current) {
      fetchTemplate();
    }

    return () => {
      isMounted.current = false;
    };
  }, [id]); // Only include 'id' in the dependency array

  const handleSubmit = async (values) => {
    setLoading(true);
    const LocalapiUrl = id
      ? `${apiUrl}/api/email-templates/${id}`
      : `${apiUrl}/api/email-templates`;
    const method = id ? 'put' : 'post';

    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios[method](LocalapiUrl, { ...values, body: editorState }, { headers });

      message.success(
        id ? 'Email template updated successfully' : 'Email template created successfully'
      );
      setLoading(false);
      navigate('/email-templates');
    } catch (error) {
      console.log(error);
      message.error('Failed to save email template');
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>{id ? 'Edit Email Template' : 'Add Email Template'}</h2>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the name' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="subject" label="Subject" rules={[{ required: true, message: 'Please enter the subject' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="body" label="Body" rules={[{ required: true, message: 'Please enter the body' }]}>
          <ReactQuill
            value={editorState}
            onChange={setEditorState}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'], // Inline styles
                  [{ header: [1, 2, 3, false] }], // Headers
                  ['blockquote', 'code-block'], // Blocks
                  [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                  [{ script: 'sub' }, { script: 'super' }], // Superscript/Subscript
                  ['link', 'image'], // Links and Images
                  [{ align: [] }], // Alignment
                  ['clean'], // Remove formatting
                ],
              },
            }}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form>
    </div>
  );
};

export default EmailTemplateEditorPage;
