import React from 'react';
import { Menu, Modal } from 'antd';
import { LogoutOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedUser } from '../middleware/userauth';
import UserMenu from './UserMenu';

const UserDashboard = () => {
  const navigate = useNavigate();
  
  if (!isAuthenticatedUser()) {
    // User is not authenticated as an admin, redirect to login
     // Clear the token from localStorage
    navigate('/'); // Redirect to the login page
    return;
  }


  return (
    <div>
      <UserMenu />
      
      
      {/* Add user dashboard content here */}
    </div>
  );
};

export default UserDashboard;
