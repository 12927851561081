import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import AdminMenu from './AdminMenu'; // Import the AdminMenu component
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import { useNavigate } from 'react-router-dom'; 
import apiUrl from '../config';

const VotersByCollector = () => {
  const navigate = useNavigate();
  const [votersByCollector, setVotersByCollector] = useState([]);
  const [votersClicked, setVotersClicked] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${apiUrl}/api/voters`, { headers });
      const voters = response.data;

      // Group voters by collector_id and count the number of voters in each group
      const groupedVoters = voters.reduce((accumulator, voter) => {
        const collectorName = voter.collector?.name || 'Unknown';
        if (!accumulator[collectorName]) {
          accumulator[collectorName] = {
            totalVoters: 0,
            votersClicked: 0,
          };
        }
        accumulator[collectorName].totalVoters++;
        if (voter.clicked_on_link) {
          accumulator[collectorName].votersClicked++;
        }
        return accumulator;
      }, {});

      // Format the data to be displayed in Ant Design Table format for Total Voters
      const totalVotersTableData = Object.entries(groupedVoters).map(([collectorName, { totalVoters }], index) => ({
        key: index,
        collectorName,
        totalVoters,
      }));

      // Format the data to be displayed in Ant Design Table format for Voters Clicked on Link
      const votersClickedTableData = Object.entries(groupedVoters).map(([collectorName, { votersClicked }], index) => ({
        key: index,
        collectorName,
        votersClicked,
      }));

      setVotersByCollector(totalVotersTableData);
      setVotersClicked(votersClickedTableData);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const totalVotersColumns = [
    {
      title: 'Collector Name',
      dataIndex: 'collectorName',
      key: 'collectorName',
    },
    {
      title: 'Total Voters',
      dataIndex: 'totalVoters',
      key: 'totalVoters',
    },
  ];

  const votersClickedColumns = [
    {
      title: 'Collector Name',
      dataIndex: 'collectorName',
      key: 'collectorName',
    },
    {
      title: 'Voters Clicked on Link',
      dataIndex: 'votersClicked',
      key: 'votersClicked',
    },
  ];

  // Check if the user is authenticated as an admin
  if (!isAuthenticatedAdmin()) {
    // Redirect to login page or handle unauthorized access here
    navigate('/'); // Change the path to your login page if needed
    return null;
  }

  return (
    <div>
      {/* Include the AdminMenu component here */}
      <AdminMenu />

      <h2>Total Voters Grouped by Collectors:</h2>
      <Table dataSource={votersByCollector} columns={totalVotersColumns} pagination={false} />
      <h2> </h2>
      <h2>Voters Clicked on Link Grouped by Collectors:</h2>
      <Table dataSource={votersClicked} columns={votersClickedColumns} pagination={false} />
    </div>
  );
};

export default VotersByCollector;
