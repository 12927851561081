import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'antd';
import AdminMenu from './AdminMenu'; // Import the AdminMenu component 
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../config'; 

const MailRemindersPage = () => {
    const navigate = useNavigate();
  const [mail2Count, setMail2Count] = useState(0);
  const [mail3Count, setMail3Count] = useState(0);
  const [mail2Voters, setMail2Voters] = useState([]);
  const [mail3Voters, setMail3Voters] = useState([]);

  
  const columns = [
    {
      title: 'Indsamler navn',
      dataIndex: 'collectorName',
      key: 'collectorName',
    },
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tlf',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Postnr',
      dataIndex: 'zip',
      key: 'zip',
    },
    {
        title: 'Trykket på Link',
        dataIndex: 'clicked_on_link',
        key: 'clicked_on_link',
        render: (text) => (text ? 'Yes' : 'No'), // Display "Yes" if true, "No" if false
      },
    {
      title: 'Dato for klik',
      dataIndex: 'clickDate',
      key: 'clickDate',
      render: (date) => (date ? new Date(date).toLocaleDateString('en-GB') : '-'),
    },
    {
        title: 'Registret dato',
        dataIndex: 'registered_date',
        key: 'registered_date',
        render: (date) => (date ? new Date(date).toLocaleDateString() : '-'), // Display the date in a formatted way, or '-' if null
    },
    {
        title: 'Antal tryk på Link',
        dataIndex: 'number_link_clicked',
        key: 'number_link_clicked',
    },
    {
      title: 'Første mail sendt',
      dataIndex: 'mail1_send',
      key: 'mail1senddate',
      render: (date) => (date ? new Date(date).toLocaleDateString('en-GB') : '-'),
    },
    {
      title: 'Rykker mail Dato',
      dataIndex: 'mail2_send',
      key: 'mail2SendDate',
      render: (date) => (date ? new Date(date).toLocaleDateString('en-GB') : '-'),
    },
    {
      title: 'Mail3 Dato',
      dataIndex: 'mail3_send',
      key: 'mail3SendDate',
      render: (date) => (date ? new Date(date).toLocaleDateString('en-GB') : '-'),
    },
  ];

  
const fetchMailReminders = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/voters/reminder2`);
    console.log(response.data.voters);
    const mail2VotersWithCollectorName = response.data.voters.map((voter) => ({
      ...voter,
      collectorName: voter.collector?.name || '', // If collector exists, get its name, otherwise use an empty string
    }));
    setMail2Count(response.data.mail2Count);
    setMail2Voters(mail2VotersWithCollectorName);

    // Fetch mail3 reminders
    const response2 = await axios.get(`${apiUrl}/api/voters/reminder3`);
    console.log(response2.data.voters);
    const mail3VotersWithCollectorName = response2.data.voters.map((voter) => ({
      ...voter,
      collectorName: voter.collector?.name || '', // If collector exists, get its name, otherwise use an empty string
    }));
    setMail3Count(response2.data.mail3Count);
    setMail3Voters(mail3VotersWithCollectorName);
  } catch (error) {
    console.error('Error fetching mail reminders:', error);
  }
};

  useEffect(() => {
    fetchMailReminders();
  }, []);

  const handleMail2Click = async () => {
    try {
      await axios.post(`${apiUrl}/api/voters/mail2`);
      // After sending mail2 reminders, fetch updated data
      fetchMailReminders();
    } catch (error) {
      console.error('Error sending mail2 reminders:', error);
    }
  };

  const handleMail3Click = async () => {
    try {
      await axios.post(`${apiUrl}/api/voters/mail3`);
      // After sending mail3 reminders, fetch updated data
      fetchMailReminders();
    } catch (error) {

      console.error('Error sending mail3 reminders:', error);
    }
  };
// Check if the user is authenticated as an admin
if (!isAuthenticatedAdmin()) {
    // Redirect to login page or handle unauthorized access here
    navigate('/'); // Change the path to your login page if needed
    return null;
  }

  return (
    <div>
      <h1>Mail Reminders Page</h1>
      {/* Include the AdminMenu component here */}
      <AdminMenu />
      <div>
        <h2>Mail3  - Voter did not click on link</h2>
        <p>Total records: {mail3Count}</p>
        <Button onClick={handleMail3Click}>Send Mail3</Button>
        <Table dataSource={mail3Voters} columns={columns} />
      </div>
      <div>
        <h2>Reminders to check mail from Indenrigsministeriet - mail 2 </h2>
        <p>Total records: {mail2Count}</p>
        <Button onClick={handleMail2Click}>Send Mail2</Button>
        <Table dataSource={mail2Voters} columns={columns} />
      </div>
      
    </div>
  );
};

export default MailRemindersPage;
