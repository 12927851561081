import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import AdminMenu from './AdminMenu'; // Import the AdminMenu component
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../config'; 

const VotersByZip = () => {
  const navigate = useNavigate();
  const [votersByZip, setVotersByZip] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
  
      const response = await axios.get(`${apiUrl}/api/voters`, { headers });
      const voters = response.data;
  
      // Group voters by zip code and count the number of voters in each group
      const groupedVoters = voters.reduce((accumulator, voter) => {
        const zipCode = voter.zip || 'Unknown';
        if (!accumulator[zipCode]) {
          accumulator[zipCode] = {
            totalVoters: 0,
          };
        }
        accumulator[zipCode].totalVoters++;
        return accumulator;
      }, {});
  
      // Format the data to be displayed in Ant Design Table format
      const tableData = Object.entries(groupedVoters).map(([zipCode, { totalVoters }], index) => ({
        key: index,
        zipCode,
        totalVoters,
      }));
  
      setVotersByZip(tableData);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };
  
  const columns = [
    {
      title: 'Zip Code',
      dataIndex: 'zipCode',
      key: 'zipCode',
    },
    {
      title: 'Total Voters',
      dataIndex: 'totalVoters',
      key: 'totalVoters',
    },
  ];

  // Check if the user is authenticated as an admin
  if (!isAuthenticatedAdmin()) {
    // Redirect to login page or handle unauthorized access here
    navigate('/'); // Change the path to your login page if needed
    return null;
  }

  return (
    <div>
      {/* Include the AdminMenu component here */}
      <AdminMenu />

      <h2>Total Voters Grouped by Zip Codes:</h2>
      <Table dataSource={votersByZip} columns={columns} pagination={false} />
    </div>
  );
};

export default VotersByZip;
