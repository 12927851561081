import React, { useEffect } from 'react';
import { Table, Button, Popconfirm, message, Modal, Form, Input } from 'antd';
import axios from 'axios';
import apiUrl from '../config'; 

const VotersTable = ({ voters, fetchData }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [currentVoter, setCurrentVoter] = React.useState(null);

  useEffect(() => {
    if (currentVoter) {
      form.setFieldsValue({
        name: currentVoter.name,
        email: currentVoter.email,
        phone: currentVoter.phone,
        zip: currentVoter.zip,
      });
    } else {
      form.resetFields(); // Clear the form fields when adding a new voter
    }
  }, [currentVoter, form]);

  const showModal = () => {
    if (currentVoter) {
      setVisible(true);
    } else {
      form.resetFields(); // Clear the form fields when adding a new voter
      setVisible(true);
    }
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      if (currentVoter) {
        updateVoter(currentVoter.id, values);
      } else {
        createVoter(values);
      }
      form.resetFields();
    });
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const editVoter = (voter) => {
    setCurrentVoter(voter);
    showModal();
  };

  const createVoter = async (data) => {
    try {
      const collectorId = localStorage.getItem('collector_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const newVoter = { ...data, collector_id: collectorId };

      await axios.post(`${apiUrl}/api/voters`, newVoter, { headers });
      message.success('Voter created successfully');
      fetchData();
    } catch (error) {
      console.log(error);
      message.error('Failed to create voter');
    }
  };
  const handleResendMail1 = async (voter) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
  
      // Send a request to the API endpoint to resend mail1 for the selected voter
      await axios.post(`${apiUrl}/api/voters/resendmail1/${voter.id}`, null, { headers });
  
      message.success('Mail1 resent successfully');
    } catch (error) {
      console.error('Error resending mail1:', error);
      message.error('Failed to resend mail1');
    }
  };

  const updateVoter = async (id, data) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios.put(`${apiUrl}/api/voters/${id}`, data, { headers });
      message.success('Voter updated successfully');
      fetchData();
    } catch (error) {
      message.error('Failed to update voter');
    }
  };

  const deleteVoter = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`${apiUrl}/api/voters/${id}`, { headers });
      message.success('Voter deleted successfully');
      fetchData();
    } catch (error) {
      console.log(error);
      message.error('Failed to delete voter');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'ZIP',
      dataIndex: 'zip',
      key: 'zip',
    },
    {
        title: 'Clicked on Link',
        dataIndex: 'clicked_on_link',
        key: 'clicked_on_link',
        render: (text) => (text ? 'Yes' : 'No'), // Display "Yes" if true, "No" if false
      },
      {
        title: 'Click Date',
        dataIndex: 'clicked_date',
        key: 'clicked_date',
        render: (date) => (date ? new Date(date).toLocaleDateString() : '-'), // Display the date in a formatted way, or '-' if null
      },
      {
        title: 'Registered Date',
        dataIndex: 'registered_date',
        key: 'registered_date',
        render: (date) => (date ? new Date(date).toLocaleDateString() : '-'), // Display the date in a formatted way, or '-' if null
      },
      {
        title: 'Number of Links Clicked',
        dataIndex: 'number_link_clicked',
        key: 'number_link_clicked',
      },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button type="primary" onClick={() => editVoter(record)}>
            Edit
          </Button>
          &nbsp;
          <Popconfirm
            title="Are you sure you want to delete this voter?"
            onConfirm={() => deleteVoter(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
          &nbsp;
           <Button type="default" onClick={() => handleResendMail1(record)}>
             Resend mail1
      </Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => editVoter(null)}>
        Add Voter
      </Button>
      <Modal
        title={currentVoter ? 'Edit Voter' : 'Add Voter'}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="zip" label="ZIP" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Table dataSource={voters} columns={columns} />
    </div>
  );
};

export default VotersTable;
