
// AllVotersPage.js
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AdminMenu from '../components/AdminMenu'; // Import the AdminMenu component
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import './AllVotersPage.css'; // Import a custom CSS file for additional styling if needed
import apiUrl from '../config'; 

const AllVotersPage = () => {
  const navigate = useNavigate();
  const [voters, setVoters] = useState([]);

  // Add your columns array here
  const columns = [
    {
      title: 'Collector Name',
      dataIndex: 'collectorName',
      key: 'collectorName',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'ZIP',
      dataIndex: 'zip',
      key: 'zip',
    },
    {
      title: 'Clicked on Link',
      dataIndex: 'clickOnLink',
      key: 'clickOnLink',
      render: (text) => (text ? 'Yes' : 'No'), // Display "Yes" if true, "No" if false
    },
    {
      title: 'Click Date',
      dataIndex: 'clickDate',
      key: 'clickDate',
    },
    {
      title: 'Registered Date',
      dataIndex: 'registeredDate',
      key: 'registeredDate',
    },
    {
      title: 'Number of Links Clicked',
      dataIndex: 'numberLinkClicked',
      key: 'numberLinkClicked',
    },
    {
      title: 'Mail1 Send Date',
      dataIndex: 'mail1SendDate',
      key: 'mail1SendDate',
    },
    {
      title: 'Mail2 Send Date',
      dataIndex: 'mail2SendDate',
      key: 'mail2SendDate',
    },
    {
      title: 'Mail3 Send Date',
      dataIndex: 'mail3SendDate',
      key: 'mail3SendDate',
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${apiUrl}/api/voters`, { headers });
      // Transform the API response to include the additional data fields in the table
  
      const formattedData = response.data.map((voter) => ({
        ...voter,
        collectorName: voter.collector?.name || '',
        clickOnLink: voter.clicked_on_link || false,
        clickDate: voter.clicked_date || null,
        registeredDate: voter.registered_date || null,
        numberLinkClicked: voter.number_link_clicked || 0,
        mail1SendDate: voter.mail1_send || null, // Add mail1_send property
        mail2SendDate: voter.mail2_send || null, // Add mail2_send property
        mail3SendDate: voter.mail3_send || null, // Add mail3_send property
        key: voter.id,
      }));
      setVoters(formattedData);
    } catch (error) {
      console.log('Error fetching data:', error);
      navigate('/');
    }
  };

  
  if (!isAuthenticatedAdmin()) {
    // User is not authenticated as an admin, redirect to login
   
    navigate('/'); // Redirect to the login page
    return;
  }
  return (
    <div className="all-voters-page-container">
      {/* Include the AdminMenu component here */}
      <AdminMenu />
      <div className="table-container">
        <h2>All Voters</h2>
        <Table dataSource={voters} columns={columns} pagination={false} />
      </div>
    </div>
  );
};

export default AllVotersPage;
