import React from 'react';
import { Table, Button, message } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import apiUrl from '../config'; 

const EmailTemplatesTable = ({ emailTemplates, fetchData }) => {
  const deleteTemplate = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios.delete(`${apiUrl}/api/email-templates/${id}`, { headers });
      message.success('Email template deleted successfully');
      fetchData();
    } catch (error) {
      message.error('Failed to delete email template');
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Subject', dataIndex: 'subject', key: 'subject' },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Link to={`/email-templates/edit/${record.id}`}>Edit</Link>
          <Button type="link" danger onClick={() => deleteTemplate(record.id)}>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }}>
        <Link to="/email-templates/add">Add Email Template</Link>
      </Button>
      <Table dataSource={emailTemplates} columns={columns} />
    </div>
  );
};

export default EmailTemplatesTable;
