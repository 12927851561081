import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import AdminMenu from './AdminMenu';
import EmailTemplatesTable from './EmailTemplatesTable';
import axios from 'axios';
import apiUrl from '../config'; 

const EmailTemplatesPage = () => {
  const navigate = useNavigate();
  const [emailTemplates, setEmailTemplates] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${apiUrl}/api/email-templates`, { headers });
      setEmailTemplates(response.data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  
  if (!isAuthenticatedAdmin()) {
    // User is not authenticated as an admin, redirect to login
    
    navigate('/'); // Redirect to the login page
    return;
  }
  return (
    <div>
      <AdminMenu />
      <h2>Email Templates</h2>
      <EmailTemplatesTable emailTemplates={emailTemplates} fetchData={fetchData} />
    </div>
  );
};

export default EmailTemplatesPage;
