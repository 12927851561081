import React from 'react';
import { Menu } from 'antd';
import { UserOutlined, LogoutOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedUser } from '../middleware/userauth';
import './AdminMenu.css'; // Import a custom CSS file for additional styling if needed

const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('email');
};

const UserMenu = () => {
  const navigate = useNavigate();

  const handleMenuClick = async (e) => {
    const key = e.key;
    if (key === 'voters') {
      navigate('/uservoters'); // Navigate to the UserVotersPage
    } else if (key === 'logout') {
      handleLogout(); // Call the handleLogout function
      // Handle Logout menu click
      navigate('/');
      // ... (other menu click handling logic)
    } else if (key === 'me') {
      // Handle Me menu click
      navigate('/me')
      // ... (other menu click handling logic)
    }
  };
  if (!isAuthenticatedUser()) {
    // User is not authenticated, redirect to login
    navigate('/');
    return null;
  }
  return (
    <Menu onClick={handleMenuClick} mode="horizontal" theme="dark">
      <Menu.Item key="voters" icon={<UserOutlined />}>
        Borger
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
      <Menu.Item key="me" icon={<EnvironmentOutlined />}>
        Mig
      </Menu.Item>
    </Menu>
  );
};

export default UserMenu;
