//App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Button, Modal } from 'antd';
import LoginPage from './components/LoginPage';
import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';
import AllVotersPage from './components/AllVotersPage';
import VotersByCollector from './components/VotersByCollector';
import CollectorsPage from './components/CollectorsPage'; // Import the CollectorsPage component
import EmailTemplatesPage from './components/EmailTemplatesPage'; // Add this import
import EmailTemplateEditorPage from './components/EmailTemplateEditorPage';
import VotersByZip from './components/VotersByZip';
import VotersPage from './components/VotersPage'
import MailRemindersPage from './components/MailRemindersPage';
import Me from './components/me';

const App = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [username, setUsername] = useState(''); // Correctly initialize the username state
  const [role, setRole] = useState('');

  const handleLoginSuccess = (role, email) => {
    setUsername(email); // Set the username state using the setUsername state-setting function
    setRole(role);
    setShowDialog(true);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage onSuccess={handleLoginSuccess} />} />
          <Route path="/userdashboard" element={<UserDashboard />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/all-voters" element={<AllVotersPage />} />
          <Route path="/votersbycollector" element={<VotersByCollector />} />
          <Route path="/collectors" element={<CollectorsPage />} /> {/* Add the CollectorsPage route */}
          <Route exact path="/email-templates" element={<EmailTemplatesPage />} /> {/* Add the CollectorsPage route */}
          <Route exact path="/email-templates/edit/:id" element={<EmailTemplateEditorPage />} />
          <Route exact path="/email-templates/add" element={<EmailTemplateEditorPage />} />
          <Route exact path="/voters-by-zip" element ={<VotersByZip />} />
          <Route exact path="/uservoters" element ={<VotersPage />} />
          <Route exact path="/me" element ={<Me />} />
          <Route exact path="/mailreminder" element={<MailRemindersPage  />} />
          
          

          
          
          
        </Routes>
      </div>
    </Router>
  );
};

export default App;

