// AdminMenu.js
import React from 'react';
import { Menu } from 'antd';
import { UserOutlined, AppstoreOutlined, MailOutlined, LogoutOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isAuthenticatedAdmin } from '../middleware/adminauth';
import './AdminMenu.css'; // Import a custom CSS file for additional styling if needed

const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('email');
};

const AdminMenu = () => {
  const navigate = useNavigate();

  const handleMenuClick = async (e) => {
    const key = e.key;
    if (key === 'admin') {
      navigate('/admindashboard'); // Navigate to the AdminDashboard
    } else if (key === 'voters') {
      navigate('/all-voters'); // Navigate to the AllVotersPage
    } else if (key === 'VotersbycollectorMenu') {
      navigate('/votersbycollector'); // Navigate to the VotersByCollectorPage
    } else if (key === 'votersByZip') {
      navigate('/voters-by-zip'); // Navigate to the VotersByZipPage
    } else if (key === 'collectors') {
      navigate('/collectors'); // Navigate to the CollectorsPage
      // Handle Collectors menu click
      // ... (other menu click handling logic)
    } else if (key === 'emailTemplates') {
      navigate('/email-templates');
      // Handle Email Templates menu click
      // ... (other menu click handling logic)
    } else if (key === 'sendEmails') {
      // Handle Send Emails menu click
      navigate('/mailreminder');
      // ... (other menu click handling logic)
    } else if (key === 'logout') {
      handleLogout(); // Call the handleLogout function
      // Handle Logout menu click
      navigate('/');
      // ... (other menu click handling logic)
    } 
  };

  return (
    <Menu onClick={handleMenuClick} mode="horizontal" theme="dark">
      {isAuthenticatedAdmin() && (
        <Menu.Item key="admin">
          Admin
        </Menu.Item>
      )}
      <Menu.Item key="voters" icon={<UserOutlined />}>
        Voters
      </Menu.Item>
      <Menu.Item key="VotersbycollectorMenu" icon={<UserOutlined />}>
        Voters by Collector
      </Menu.Item>
      <Menu.Item key="votersByZip" icon={<UserOutlined />}>
        Voters by Zip
      </Menu.Item>
      <Menu.Item key="collectors" icon={<AppstoreOutlined />}>
        Collectors
      </Menu.Item>
      <Menu.Item key="emailTemplates" icon={<MailOutlined />}>
        Email Templates
      </Menu.Item>
      <Menu.Item key="sendEmails" icon={<MailOutlined />}>
        Send Emails
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
      
    </Menu>
  );
};

export default AdminMenu;
