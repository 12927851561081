import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../config'; 

const LoginPage = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      console.log('url',apiUrl);
      console.log('Login form values:', values);
      const response = await axios.post(`${apiUrl}/api/collectors/login`, values);
      const { token } = response.data;
      console.log('Login ', response);
      const decodedToken = jwt_decode(token);
      const role = decodedToken.role;
      const collector_id =decodedToken.collector_id; 
  
      
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('email', values.email);
      localStorage.setItem('collector_id',collector_id );

      message.success('Login successful');
      onSuccess(role, values.email);
      navigate(`/${role.toLowerCase()}dashboard`); // Navigate to respective dashboard
    } catch (error) {
      console.log('Login failed', error);
      message.error('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h2>Velkommen til Kristendemokraternes stemmeindsamling</h2>
      <Form onFinish={handleLogin} style={{ width: '300px', margin: '0 auto' }}>
        <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password!' }]}>
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
