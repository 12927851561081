import React, { useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import axios from 'axios';
import apiUrl from '../config'; 

const CollectorsTable = ({ collectors, fetchData }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [currentCollector, setCurrentCollector] = React.useState(null);

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Role', dataIndex: 'role', key: 'role' },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => editCollector(record)}>Edit</Button>
          <Button type="link" danger onClick={() => deleteCollector(record.id)}>Delete</Button>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (currentCollector) {
      form.setFieldsValue({
        name: currentCollector.name,
        email: currentCollector.email,
        role: currentCollector.role,
        password: currentCollector.password,
      });
    } else {
      form.resetFields(); // Clear the form fields when adding a new collector
    }
  }, [currentCollector, form]);

  const showModal = () => {
    if (currentCollector) {
      setVisible(true);
    } else {
      form.resetFields(); // Clear the form fields when adding a new collector
      form.setFieldsValue({
        password: '123456', // Set the default password here only when adding
      });
      setVisible(true);
    }
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      if (currentCollector) {
        updateCollector(currentCollector.id, values);
      } else {
        createCollector(values);
      }
      form.resetFields();
    });
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };
/*
  const editCollector = (collector) => {
    setCurrentCollector(collector);
  };
*/
const editCollector = (collector) => {
    setCurrentCollector(collector);
    showModal(); //
}

  const createCollector = async (data) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      console.log('Create collector');
      await axios.post(`${apiUrl}/api/collectors`, data, { headers });
      message.success('Collector created successfully');
      fetchData();
    } catch (error) {
      console.log(error);
      message.error('Failed to create collector');
    }
  };

  const updateCollector = async (id, data) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios.put(`${apiUrl}/api/collectors/${id}`, data, { headers });
      message.success('Collector updated successfully');
      fetchData();
    } catch (error) {
      message.error('Failed to update collector');
    }
  };

  const deleteCollector = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`${apiUrl}/api/collectors/${id}`, { headers });
      message.success('Collector deleted successfully');
      fetchData();
    } catch (error) {
        console.log(error);
      message.error('Failed to delete collector');
    }
  };

  return (
    <div>
      <Button type="primary" onClick={() => editCollector(null)}>
        Add Collector
      </Button>
      <Modal
        title={currentCollector ? 'Edit Collector' : 'Add Collector'}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {/* Add the password input with the default value */}
          
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input type="password" defaultValue="123456" />
            </Form.Item>
          
        </Form>
      </Modal>
      <Table dataSource={collectors} columns={columns} />
    </div>
  );
};

export default CollectorsTable;
